import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import clsx from 'clsx'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import AboutUsLinks from '../../components/aboutuslinks'
import Firebase from '../../components/Firebase'
import Layout from '../../components/layout'
import NewHeader from '../../components/NewHeader'
import SEO from '../../components/seo'
import Album from '../../images/album.jpg'

const useStyles = makeStyles(theme => ({
  titleFont: {
    fontFamily: 'Volkhov',
    fontSize: 44,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  marginMainTop: {
    marginTop: -150,
    marginBottom: 84,
  },
  marginIPADTop: {
    marginTop: -200,
    marginBottom: 84,
  },
  mt24: {
    marginTop: 24,
  },
  white: {
    color: '#fff',
  },
  paddingAll24: {
    padding: 18,
  },
  pall: {
    padding: 24,
  },
}))

const Gallery = props => {
  const classes = useStyles()
  const matches = useMediaQuery('(max-width:1024px)')
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    Firebase.firestore()
      .collection('albums')
      .get()
      .then(function(snapshot) {
        setLoading(false)
        snapshot.forEach(function(doc) {
          setRows(old => [...old, doc])
        })
      })
  }, [])

  return (
    <div>
      <Layout>
        <SEO title="Gallery" />
        <div>
          <NewHeader title="About us" image={Album} />
          {matches ? (
            <div className={classes.marginIPADTop}>
              <Container>
                <Typography className={clsx(classes.titleFont, classes.white)}>
                  Gallery
                </Typography>
                <Grid container spacing={2} className={classes.mt24}>
                  <Hidden xsDown mdDown>
                    <Grid item md={9} xs={12} sm={12}>
                      <Paper square>
                        <div
                          style={{ backgroundColor: '#3c5eab', padding: 6 }}
                        ></div>
                        <div className={classes.paddingAll24}>
                          <Typography
                            variant="body1"
                            className={classes.mt24}
                            align="justify"
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: props.content,
                              }}
                            ></div>
                          </Typography>
                        </div>
                      </Paper>
                    </Grid>
                  </Hidden>
                  <Grid item md={12} xs={12} sm={12}>
                    <Paper square>
                      <div
                        style={{ backgroundColor: '#3c5eab', padding: 6 }}
                      ></div>
                      <div className={classes.paddingAll24}>
                        <Typography
                          variant="body1"
                          className={classes.mt24}
                          align="justify"
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: props.content }}
                          ></div>
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                  <Hidden xsDown mdDown>
                    <Grid item md={3} xs={12}>
                      <Paper square>
                        <div
                          style={{ backgroundColor: '#b12229', padding: 6 }}
                        ></div>
                        <div className={classes.paddingAll24}>
                          <AboutUsLinks />
                        </div>
                      </Paper>
                    </Grid>
                  </Hidden>
                </Grid>
              </Container>
            </div>
          ) : (
            ''
          )}
          <div className={classes.marginMainTop}>
            <Container>
              <Typography className={clsx(classes.titleFont, classes.white)}>
                Gallery
              </Typography>
              <Grid container spacing={2} className={classes.mt24}>
                {matches ? (
                  ''
                ) : (
                  <Grid item md={9} xs={12} sm={12}>
                    <Paper square>
                      <div
                        style={{ backgroundColor: '#3c5eab', padding: 6 }}
                      ></div>

                      <div>
                        {loading ? (
                          <div align="center" className={classes.pall}>
                            <CircularProgress />
                            <br />
                            <Typography variant="h5">Loading Albums</Typography>
                          </div>
                        ) : (
                          <Grid className={classes.pall} container spacing={2}>
                            {rows.map((v, k) => (
                              <Grid item md={4} key={k}>
                                <Link
                                  to={`/photos/?aid=${v.id}`}
                                  style={{ textDecoration: 'none' }}
                                >
                                  <Card>
                                    <CardActionArea>
                                      <img
                                        src={v.data().downloadUrl}
                                        alt={v.data().title}
                                        height="300"
                                      />
                                      <CardContent>
                                        <Typography variant="h6">
                                          {v.data().title}
                                        </Typography>
                                        <Typography component="Lizard">
                                          {v.data().desc}
                                        </Typography>
                                      </CardContent>
                                    </CardActionArea>
                                  </Card>
                                </Link>
                              </Grid>
                            ))}
                          </Grid>
                        )}
                      </div>
                    </Paper>
                  </Grid>
                )}

                <Hidden xsDown mdDown>
                  <Grid item md={3} xs={12}>
                    <Paper square>
                      <div
                        style={{ backgroundColor: '#b12229', padding: 6 }}
                      ></div>
                      <div className={classes.paddingAll24}>
                        <AboutUsLinks />
                      </div>
                    </Paper>
                  </Grid>
                </Hidden>
              </Grid>
            </Container>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Gallery
